import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import { createReducer } from "utils/ReducerUtils";
import type {
  PricePlansConfigResponseType,
  UpcomingInvoiceSummaryResponseType,
} from "./billingReducer.types";

export interface BillingState {
  plansInfo: PricePlansConfigResponseType;
  isPricePlansConfigLoading: boolean;
  upcomingInvoiceSummary: UpcomingInvoiceSummaryResponseType;
  isUpcomingInvoiceSummaryLoading: boolean;
  activeUsers: number;
}

const initialState: BillingState = {
  plansInfo: {
    maxSeats: 0,
    pricePerSeat: 0,
    minSeats: 0,
  },
  isPricePlansConfigLoading: true,
  upcomingInvoiceSummary: {
    lineItems: [],
    subtotal: 0,
    totalExcludingTax: 0,
    tax: 0,
    total: undefined,
    amountDue: 0,
    periodStart: undefined,
    periodEnd: undefined,
  },
  isUpcomingInvoiceSummaryLoading: true,
  activeUsers: 0,
};

export const handlers = {
  [ReduxActionTypes.FETCH_UPCOMING_INVOICE_SUMMARY]: (state: BillingState) => {
    return { ...state, isUpcomingInvoiceSummaryLoading: true };
  },
  [ReduxActionTypes.FETCH_UPCOMING_INVOICE_SUMMARY_SUCCESS]: (
    state: BillingState,
    action: ReduxAction<UpcomingInvoiceSummaryResponseType>,
  ) => {
    return {
      ...state,
      upcomingInvoiceSummary: {
        ...state.upcomingInvoiceSummary,
        ...action.payload,
      },
      isUpcomingInvoiceSummaryLoading: false,
    };
  },
  [ReduxActionErrorTypes.FETCH_UPCOMING_INVOICE_SUMMARY_ERROR]: (
    state: BillingState,
  ) => {
    return { ...state, isUpcomingInvoiceSummaryLoading: false };
  },
  [ReduxActionTypes.GET_PRICE_PLANS_CONFIG]: (state: BillingState) => {
    return { ...state, isPricePlansConfigLoading: true };
  },
  [ReduxActionTypes.GET_PRICE_PLANS_CONFIG_SUCCESS]: (
    state: BillingState,
    action: ReduxAction<PricePlansConfigResponseType>,
  ) => {
    return {
      ...state,
      plansInfo: action.payload,
      isPricePlansConfigLoading: false,
    };
  },
  [ReduxActionErrorTypes.GET_PRICE_PLANS_CONFIG_ERROR]: (
    state: BillingState,
  ) => {
    return { ...state, isPricePlansConfigLoading: false };
  },
  [ReduxActionTypes.FETCH_ACTIVE_USERS_SUCCESS]: (
    state: BillingState,
    action: ReduxAction<number>,
  ) => {
    return {
      ...state,
      activeUsers: action.payload,
    };
  },
};
export default createReducer(initialState, handlers);
