import { useDispatch, useSelector } from "react-redux";
import { change, getFormValues } from "redux-form";
import { get } from "lodash";
import { useCallback } from "react";

const SEARCH_ALL_FIELD =
  "actionConfiguration.formData.aiChatAssistant.input.searchAllDocuments";

export const useSearchAll = (
  formName: string,
): [boolean, (value: boolean) => void] => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => getFormValues(formName)(state));

  const isSearchAll = get(formData, SEARCH_ALL_FIELD, true);

  const setIsSearchAll = useCallback(
    (value: boolean) => {
      dispatch(change(formName, SEARCH_ALL_FIELD, value));
    },
    [dispatch, formName],
  );

  return [isSearchAll, setIsSearchAll];
};
