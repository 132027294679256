/**
 * Configuration file for OpenAI assistant models
 * Provides mapping between models and their corresponding assistant IDs from environment variables
 */

export enum ModelType {
  GPT_4O = "gpt-4o",
  GPT_4O_MINI = "gpt-4o-mini",
  O1 = "o1",
  O3_MINI = "o3-mini",
}

interface ModelAssistantConfig {
  assistantId: string;
}

interface ModelAssistantConfigs {
  [key: string]: ModelAssistantConfig;
}

/**
 * Maps model names to their corresponding assistant IDs loaded from environment variables
 * Fallbacks to empty string if environment variable is not set
 */
export const modelAssistantConfigs: ModelAssistantConfigs = {
  [ModelType.GPT_4O]: {
    assistantId: process.env.OPENAI_ASSISTANT_ID_GPT_4O || "",
  },
  [ModelType.GPT_4O_MINI]: {
    assistantId: process.env.OPENAI_ASSISTANT_ID_GPT_4O_MINI || "",
  },
  [ModelType.O1]: {
    assistantId: process.env.OPENAI_ASSISTANT_ID_O1 || "",
  },
  [ModelType.O3_MINI]: {
    assistantId: process.env.OPENAI_ASSISTANT_ID_O3_MINI || "",
  },
};

// Default model to use when not specified
export const DEFAULT_MODEL = ModelType.GPT_4O;

/**
 * Legacy assistant IDs for backward compatibility
 */
export const LEGACY_ASSISTANT_ID = process.env.OPENAI_ASSISTANT_ID || "";
export const VISUALIZATION_ASSISTANT_ID =
  process.env.OPENAI_ASSISTANT_ID_VISUALIZATION || "";

/**
 * Gets assistant ID for the specified model
 * Falls back to the model's default assistant ID if not available
 *
 * @param model - The model name to get assistant ID for
 * @returns The assistant ID for the specified model or default assistant ID
 */
export function getAssistantIdForModel(model?: string): string {
  if (!model) {
    return (
      modelAssistantConfigs[DEFAULT_MODEL]?.assistantId || LEGACY_ASSISTANT_ID
    );
  }

  // Normalize model name for lookup (trim and convert to lowercase)
  const normalizedModel = model.trim().toLowerCase();

  // Check if we have a specific configuration for this model
  for (const [modelKey, config] of Object.entries(modelAssistantConfigs)) {
    if (modelKey.toLowerCase() === normalizedModel && config.assistantId) {
      return config.assistantId;
    }
  }

  // Fall back to legacy assistant ID if no match is found
  return LEGACY_ASSISTANT_ID;
}
