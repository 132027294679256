import { LICENSE_CHECK_PATH } from "./../../constants/routes/baseRoutes";
export * from "ce/utils/signupHelpers";
import { setCurrentApplicationIdForCreateNewApp } from "actions/onboardingActions";
import type { RedirectUserAfterSignupProps } from "ce/utils/signupHelpers";
import {
  SIGNUP_SUCCESS_URL,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
  VIEWER_PATH,
  VIEWER_PATH_DEPRECATED,
  APPLICATIONS_URL,
} from "constants/routes";
import { error } from "loglevel";
import { matchPath } from "react-router";
import { getIsSafeRedirectURL } from "utils/helpers";
import history from "utils/history";

// TODO: Try to refactor this function to make it more readable
export const redirectUserAfterSignup = (
  props: RedirectUserAfterSignupProps,
) => {
  const {
    dispatch,
    isAiAgentFlowEnabled,
    isOnLoginPage,
    redirectUrl,
    shouldEnableFirstTimeUserOnboarding:
      shouldEnableFirstTimeUserOnboardingAsString,
    validLicense,
  } = props;

  if (!redirectUrl && !isOnLoginPage) {
    return history.replace(APPLICATIONS_URL);
  }

  if (!validLicense) {
    return history.replace(
      `${LICENSE_CHECK_PATH}?redirectUrl=${redirectUrl}&enableFirstTimeUserExperience=true`,
    );
  }

  const isSignupSuccessPage = window.location.pathname == SIGNUP_SUCCESS_URL;
  const shouldEnableFirstTimeUserOnboarding =
    shouldEnableFirstTimeUserOnboardingAsString === "true";

  try {
    if (isSignupSuccessPage && shouldEnableFirstTimeUserOnboarding) {
      let urlObject;

      try {
        urlObject = new URL(redirectUrl);
      } catch (e) {}
      const match = matchPath<{
        basePageId: string;
        baseApplicationId: string;
      }>(urlObject?.pathname ?? redirectUrl, {
        path: [
          BUILDER_PATH,
          BUILDER_PATH_DEPRECATED,
          VIEWER_PATH,
          VIEWER_PATH_DEPRECATED,
        ],
        strict: false,
        exact: false,
      });
      const { baseApplicationId, basePageId } = match?.params || {};

      /** ! Dev Note:
       *  setCurrentApplicationIdForCreateNewApp & firstTimeUserOnboardingInit
       *  in the following block support only applicationId
       *  but since baseId and id are same for applications created outside git context
       *  and since these redux actions are only called during onboarding,
       *  passing baseApplicationId as applicationId should be fine
       * **/
      if (baseApplicationId || basePageId) {
        dispatch(
          setCurrentApplicationIdForCreateNewApp(baseApplicationId as string),
        );

        // For ai agent flow, we don't need to redirect to applications page
        if (isAiAgentFlowEnabled) {
          return history.replace(
            new URL(redirectUrl, window.location.origin).pathname,
          );
        }

        return history.replace(APPLICATIONS_URL);
      } else {
        if (!urlObject) {
          try {
            urlObject = new URL(redirectUrl, window.location.origin);
          } catch (e) {}
        }

        const newRedirectUrl = urlObject?.toString() || "";

        if (getIsSafeRedirectURL(newRedirectUrl)) {
          window.location.replace(newRedirectUrl);
        }
      }
    } else if (getIsSafeRedirectURL(redirectUrl)) {
      window.location.replace(redirectUrl);
    }
  } catch (e) {
    error("Error handling the redirect url", e);
  }
};
