export * from "ce/sagas/NavigationSagas";

import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { all, fork, takeEvery } from "redux-saga/effects";
import {
  handleRouteChange,
  setSelectedWidgetsSaga,
} from "ce/sagas/NavigationSagas";

export default function* rootSaga() {
  yield all([
    takeEvery(ReduxActionTypes.ROUTE_CHANGED, handleRouteChange),
    // EE sagas called after this
    takeEvery(ReduxActionTypes.ROUTE_CHANGED, EE_handleRouteChange),
  ]);
}

import type { ReduxAction } from "actions/ReduxActionTypes";
import type { AppsmithLocationState } from "utils/history";
import type { Location } from "history";
import { hasNavigatedOutOfPage } from "ee/pages/Editor/Explorer/helpers";
import { call } from "redux-saga/effects";
import { clearAllWindowMessageListeners } from "./WindowMessageListener/WindowMessageListenerSagas";
import { getAppsmithConfigs } from "ee/configs";
import { IDE_TYPE } from "ee/IDE/Interfaces/IDETypes";
import { identifyEntityFromPath } from "navigation/FocusEntity";
import { updatePackageIDETabsOnRouteChangeSaga } from "ee/sagas/packageIDESagas";
import { getIDETypeByUrl } from "ee/entities/IDE/utils";
import log from "loglevel";

export const { cloudHosting } = getAppsmithConfigs();

let previousPath: string;

export function* EE_handleRouteChange(
  action: ReduxAction<{ location: Location<AppsmithLocationState> }>,
) {
  const { pathname, state } = action.payload.location;

  try {
    const IDEType = getIDETypeByUrl(pathname);
    const entityInfo = identifyEntityFromPath(pathname);

    if (!cloudHosting && hasNavigatedOutOfPage(previousPath, pathname)) {
      yield call(clearAllWindowMessageListeners);
    }

    if (IDEType === IDE_TYPE.Package) {
      yield fork(updatePackageIDETabsOnRouteChangeSaga, entityInfo);
    }

    if (IDEType === IDE_TYPE.UIPackage) {
      yield fork(setSelectedWidgetsSaga, state?.invokedBy);
    }
  } catch (e) {
    log.error("Error in focus change", e);
  } finally {
    previousPath = pathname;
  }
}
