import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Callout,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from "@appsmith/ads";
import {
  createMessage,
  UPGRADE_PLAN_MODAL_CALLOUT,
  UPGRADE_PLAN_MODAL_TITLE,
  UPGRADE_PLAN_MODAL_TITLE_AI,
} from "ee/constants/messages";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { checkoutRequestInit } from "ee/actions/organizationActions";
import { isCheckingOut } from "ee/selectors/organizationSelectors";
import {
  getActiveUsers,
  getPricePlansInfo,
} from "ee/selectors/billingSelectors";
import {
  fetchActiveUsers,
  getPricePlansConfig,
} from "ee/actions/billingActions";
import { getIsAiAgentFlowEnabled } from "ee/selectors/aiAgentSelectors";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TotalDue = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid var(--ads-v2-color-border);
`;

const TotalDueTitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: var(--ads-v2-color-fg);
`;

const CheckoutButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
`;

interface Props {
  isModalOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function UpgradePlanModal(props: Props) {
  const [isOpen, setIsOpen] = useState(props.isModalOpen);
  const dispatch = useDispatch();
  const isLoading = useSelector(isCheckingOut);
  const pricePlansConfig = useSelector(getPricePlansInfo);
  const activeUsers = useSelector(getActiveUsers);
  const isAiAgentFlowEnabled = useSelector(getIsAiAgentFlowEnabled);

  useEffect(
    function fetchActiveUsersEffect() {
      dispatch(fetchActiveUsers());
    },
    [dispatch],
  );

  useEffect(
    function fetchPricePlansConfigEffect() {
      dispatch(getPricePlansConfig());
    },
    [dispatch],
  );

  useEffect(
    function setModalOpenStateEffect() {
      setIsOpen(props.isModalOpen);
    },
    [props.isModalOpen],
  );

  const handleCheckoutClick = useCallback(() => {
    dispatch(checkoutRequestInit());
  }, [dispatch]);

  const totalDue = pricePlansConfig.pricePerSeat * activeUsers || 0;

  const activeSeats =
    activeUsers > 1 ? `${activeUsers} Seats` : `${activeUsers} Seat`;

  return (
    <Modal
      onOpenChange={(open: boolean) => props.onOpenChange(open)}
      open={isOpen}
    >
      <ModalContent style={{ width: "500px" }}>
        <ModalHeader>
          {createMessage(
            isAiAgentFlowEnabled
              ? UPGRADE_PLAN_MODAL_TITLE_AI
              : UPGRADE_PLAN_MODAL_TITLE,
          )}
        </ModalHeader>
        <ModalBody>
          <Callout kind="info">
            {createMessage(UPGRADE_PLAN_MODAL_CALLOUT)}
          </Callout>
          <Row>
            <Content>
              <Text color="var(--ads-v2-color-fg)" kind="body-m">
                {activeUsers} x {isAiAgentFlowEnabled ? "Team" : "Business"}{" "}
                Plan
              </Text>
              <Text color="var(--ads-v2-color-fg-muted)" kind="body-m">
                ${pricePlansConfig.pricePerSeat} x {activeSeats} x 1 Month
              </Text>
            </Content>
            <Text color="var(--ads-v2-color-fg)" kind="body-m">
              ${totalDue}
            </Text>
          </Row>
          <TotalDue>
            <TotalDueTitle>Total due</TotalDueTitle>
            <Text color="var(--ads-v2-color-fg)" kind="body-m">
              ${totalDue}
            </Text>
          </TotalDue>
        </ModalBody>
        <ModalFooter>
          <CheckoutButton
            isLoading={isLoading}
            onClick={handleCheckoutClick}
            size="md"
          >
            Checkout
          </CheckoutButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
