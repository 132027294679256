export class AppsmithError extends Error {
  errorMessage: string;
  statusCode: number;
  errorCode: string;
  errorTitle: string;
  errorType: string;
  constructor(errorCode: ErrorCode, ...args: string[]) {
    super(errorCode.errorMessage(...args));
    this.errorMessage = errorCode.errorMessage(...args);
    this.statusCode = errorCode.statusCode;
    this.errorCode = errorCode.errorCode;
    this.errorTitle = errorCode.title;
    this.errorType = errorCode.errorType;
  }
}

enum ErrorType {
  CHAT_ERROR = "CHAT_ERROR",
  RERANK_ERROR = "RERANK_ERROR",
  RAG_ERROR = "RAG_ERROR",
}

export const ErrorCodes = {
  THREAD_ID_NOT_FOUND: {
    title: "Thread ID not found",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) => `Thread ID ${threadId} not found`,
    errorCode: "AI-CHAT-4040",
    statusCode: 200,
  },
  RAG_DOCUMENTS_FETCH_ERROR: {
    title: "RAG documents could not be fetched",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: () => `RAG documents could not be fetched`,
    errorCode: "AI-RAG-4001",
    statusCode: 200,
  },
  RAG_ACCESS_TOKEN_GENERATION_FAILED: {
    title: "RAG access token generation failed",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: () => `RAG access token generation failed`,
    errorCode: "AI-RAG-4002",
    statusCode: 200,
  },
  RAG_CONFIGURATION_FETCH_ERROR: {
    title: "RAG configuration could not be fetched",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: () => `RAG configuration could not be fetched`,
    errorCode: "AI-RAG-4003",
    statusCode: 200,
  },
  RAG_CONFIGURATION_INVALID_ERROR: {
    title: "RAG configuration stored in DB is invalid",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: () => `RAG configuration stored in DB is invalid`,
    errorCode: "AI-RAG-4004",
    statusCode: 200,
  },
  RAG_CITATION_FILE_NOT_FOUND_ERROR: {
    title: "RAG citation file not found",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: (citationId?: string) =>
      `RAG citation file with id ${citationId} not found`,
    errorCode: "AI-RAG-4005",
    statusCode: 200,
  },
  RAG_CITATION_FILE_NO_LOCATION_ERROR: {
    title: "RAG citation file location not found",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: (citationId?: string) =>
      `RAG citation file location for id ${citationId} not found`,
    errorCode: "AI-RAG-4006",
    statusCode: 200,
  },
  RAG_CITATION_FILE_MISSING_ARGUMENTS_ERROR: {
    title: "RAG citation file missing arguments",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: (missingArguments?: string) =>
      `RAG citation file missing arguments: ${missingArguments}`,
    errorCode: "AI-RAG-4007",
    statusCode: 200,
  },
  CANCEL_RUN_ERROR: {
    title: "Error canceling run",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (errorMessage?: string) =>
      `Error canceling run: ${errorMessage}`,
    errorCode: "AI-CHAT-4051",
    statusCode: 200,
  },
  THREAD_ID_EMPTY: {
    title: "Thread ID is empty",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: () => `Thread ID is empty`,
    errorCode: "AI-CHAT-4000",
    statusCode: 200,
  },
  THREAD_DELETE_FAILED: {
    title: "Thread delete failed",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) =>
      `Thread delete failed for thread ID ${threadId}`,
    errorCode: "AI-CHAT-5001",
    statusCode: 500,
  },
  THREAD_SAVE_FAILED: {
    title: "Thread save failed",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) =>
      `Thread save failed for thread ID ${threadId}`,
    errorCode: "AI-CHAT-5002",
    statusCode: 500,
  },
  THREAD_CREATION_FAILED: {
    title: "Thread creation failed",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: () => `Thread creation failed`,
    errorCode: "AI-CHAT-5003",
    statusCode: 500,
  },
  CHAT_MESSAGE_SEND_FAILED: {
    title: "Chat message send failed",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) =>
      `Chat message send failed for thread ID ${threadId}`,
    errorCode: "AI-CHAT-5004",
    statusCode: 500,
  },
  SEND_MESSAGE_STREAM_ERROR: {
    title: "Send message stream error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) =>
      `Send message stream error for thread ID ${threadId}`,
    errorCode: "AI-CHAT-5005",
    statusCode: 500,
  },
  SUBMIT_TOOL_OUTPUT_STREAM_ERROR: {
    title: "Submit tool output stream error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) =>
      `Submit tool output stream error for thread ID ${threadId}`,
    errorCode: "AI-CHAT-5006",
    statusCode: 500,
  },
  STREAM_PROCESSING_ERROR: {
    title: "Stream processing error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string, errorMessage?: string) =>
      `Stream processing error for thread ID ${threadId}. Error Message: ${errorMessage}`,
    errorCode: "AI-CHAT-5007",
    statusCode: 500,
  },
  GENERIC_STREAM_ERROR: {
    title: "Generic stream error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (errorMessage?: string) =>
      `Generic stream error. Error Message: ${errorMessage}`,
    errorCode: "AI-CHAT-5008",
    statusCode: 500,
  },
  STREAM_TIMEOUT: {
    title: "Stream timed out",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) =>
      `Stream timed out for thread ID ${threadId}`,
    errorCode: "AI-CHAT-5009",
    statusCode: 500,
  },
  INTERNAL_SERVER_ERROR: {
    title: "Internal Server Error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (errorMessage?: string) =>
      `Internal Server Error ${errorMessage}`,
    errorCode: "AI-CHAT-5000",
    statusCode: 200,
  },
  RAG_DOCUMENTS_DELETE_ERROR: {
    title: "RAG documents could not be deleted",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: (message?: string) =>
      `RAG documents could not be deleted, error: ${message}`,
    errorCode: "AI-RAG-4008",
    statusCode: 200,
  },
  RAG_DOCUMENT_RE_SYNC_ERROR: {
    title: "RAG document re-sync failed",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: (message?: string) =>
      `RAG document re-sync failed, error: ${message}`,
    errorCode: "AI-RAG-4009",
    statusCode: 200,
  },
  INVALID_DB_CONNECTION_STRING: {
    title: "Invalid DB connection string",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (message?: string) =>
      `Invalid DB connection string, error: ${message}`,
    errorCode: "AI-CHAT-4050",
    statusCode: 200,
  },
  RERANK_ERROR: {
    title: "Rerank error",
    errorType: ErrorType.RERANK_ERROR,
    errorMessage: (reranker?: string, message?: string) =>
      `Rerank error, reranker: ${reranker}, error: ${message}`,
    errorCode: "AI-RERANK-5001",
    statusCode: 200,
  },
  RAG_SOURCE_NOT_FOUND_ERROR: {
    title: "RAG source not found",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: (documentId?: string) =>
      `RAG source for document id ${documentId} not found`,
    errorCode: "AI-RAG-4041",
    statusCode: 200,
  },
  RAG_SOURCE_INVALID_ERROR: {
    title: "RAG source invalid",
    errorType: ErrorType.RAG_ERROR,
    errorMessage: () => `RAG source is invalid`,
    errorCode: "AI-RAG-4042",
    statusCode: 200,
  },
  MESSAGES_FETCH_ERROR: {
    title: "Messages fetch error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string) =>
      `Could not fetch messages for thread ID ${threadId}`,
    errorCode: "AI-CHAT-4044",
    statusCode: 200,
  },
  THREAD_FETCH_ERROR: {
    title: "Thread fetch error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (userWidgetIdentifier?: string) =>
      `Could not fetch threads for user widget identifier ${userWidgetIdentifier}`,
    errorCode: "AI-CHAT-4045",
    statusCode: 200,
  },
  SUBMIT_TOOL_OUTPUTS_CREATE_RUN_ERROR: {
    title: "Error while creating run for submitting tool outputs",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (threadId?: string, runId?: string) =>
      `Error while creating run for thread ID ${threadId}, run ID ${runId} for submitting tool outputs`,
    errorCode: "AI-CHAT-4046",
    statusCode: 200,
  },
  REQUEST_TIMEOUT_ERROR: {
    title: "Request timeout error",
    errorType: ErrorType.CHAT_ERROR,
    errorMessage: (message?: string) => `Request timed out, error: ${message}`,
    errorCode: "AI-CHAT-5010",
    statusCode: 500,
  },
} as const;

export type ErrorCode = (typeof ErrorCodes)[keyof typeof ErrorCodes];
