import React, { useEffect } from "react";
import { Button, Text, type ButtonProps } from "@appsmith/ads";
import {
  createMessage,
  CONTACT_US_CTA,
  ENTERPRISE_CONTACT_MESSAGE_INTERCOM,
} from "ee/constants/messages";
import { getIsAiAgentFlowEnabled } from "ee/selectors/aiAgentSelectors";
import { useSelector } from "react-redux";
import { getCurrentUser } from "selectors/usersSelectors";

// This is required to satisfy the type checker for the ChiliPiper script
// Otherwise it compains that ChiliPiper is not defined on window
declare global {
  interface Window {
    ChiliPiper?: {
      submit: (
        appName: string,
        trigger: string,
        data: {
          trigger: string;
          lead: Record<string, string>;
        },
      ) => void;
    };
  }
}

interface Props extends ButtonProps {
  text?: string;
}

export function ContactUsButton(props: Props) {
  const isAiAgentFlowEnabled = useSelector(getIsAiAgentFlowEnabled);
  const user = useSelector(getCurrentUser);

  // For normal apps, we just load the intercom popup,
  // but for ai agent flow, we need to load the chili piper script
  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const onClick = (
    e: React.MouseEvent<HTMLButtonElement> &
      React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();

    if (isAiAgentFlowEnabled) {
      window.ChiliPiper?.submit("appsmith", "inbound_router", {
        trigger: "InAppButton",
        lead: {
          PersonFirstName: user?.name?.split(" ")[0] || "First Name",
          PersonLastName: user?.name?.split(" ")[1] || "Last Name",
          PersonEmail: user?.email || "",
          "f71347a8-f115-4d2a-a3e3-50963c621be3": "",
          "3f9d4143-a29c-43d3-8041-85557ea7f98d": "",
          "807d59e2-a6f4-4371-8ffd-936c11a97175": "",
          "3f438227-9cdd-4477-8e50-77b28b59c571": "",
          "1e8ff709-e1c5-4923-b216-592c6e4caaf5": "",
          "c108698f-51ea-4f44-95af-d28cc829d9ab": "",
          "58a951f8-4b0d-4c17-aeda-f306f9f3db22": "",
          "27cb1a9d-88a8-436a-bf0e-21004b516e74": "",
          "d9dbcb3e-2953-4b27-87d5-35da7d18222a": "AI assistants",
          "e27eaa1b-e30f-48e4-891f-1d398f7dda29": "",
        },
      });

      return;
    }

    if (window.Intercom) {
      window.Intercom(
        "showNewMessage",
        createMessage(ENTERPRISE_CONTACT_MESSAGE_INTERCOM),
      );
    }
  };

  useEffect(
    function loadChiliPiperScript() {
      if (!isAiAgentFlowEnabled) return;

      const script = document.createElement("script");

      script.src =
        "https://appsmith.chilipiper.com/concierge-js/cjs/concierge.js";
      script.crossOrigin = "anonymous";
      script.type = "text/javascript";
      document.head.appendChild(script);
    },
    [isAiAgentFlowEnabled],
  );

  return (
    <Button
      className="mb-2"
      kind="secondary"
      onClick={onClick}
      size="md"
      {...props}
    >
      <Text kind="body-m">{props.text || createMessage(CONTACT_US_CTA)}</Text>
    </Button>
  );
}
