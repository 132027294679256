export * from "ce/reducers";
import type { AppState as CE_AppState } from "ce/reducers";
import { reducerObject as CE_AppReducer } from "ce/reducers";
import { combineReducers } from "redux";
import aclReducer, { type AclReduxState } from "./aclReducers";
import auditLogsReducer, { type AuditLogsReduxState } from "./auditLogsReducer";
import environmentReducer, {
  type EnvironmentsReduxState,
} from "./environmentReducer";
import AIReducer, { type AIReduxState } from "./AIReducer";
import ProvisioningReducer, {
  type ProvisioningReduxState,
} from "./ProvisioningReducer";
import type { PackagesReducerState } from "./entityReducers/packagesReducer";
import type { ModulesReducerState } from "./entityReducers/modulesReducer";
import type { ModuleInstanceReducerState } from "./entityReducers/moduleInstancesReducer";
import type { ModuleInstancePaneState } from "./uiReducers/moduleInstancePaneReducer";
import type { WorkflowsReducerState } from "./entityReducers/workflowsReducer";
import type { ModuleInstanceEntitiesReducerState } from "./entityReducers/moduleInstanceEntitiesReducer";
import type { ActionDataState } from "ee/reducers/entityReducers/actionsReducer";
import type { GitExtendedState } from "./uiReducers/gitExtendedReducer";
import type { WorkflowHistoryPaneState } from "./uiReducers/workflowHistoryPaneReducer";
import type { ModuleActiveActionsState } from "./uiReducers/moduleActiveActionsReducer";
import type { WorkflowPluginState } from "./uiReducers/workflowPluginReducer";
import type { WorkflowIDEState } from "./uiReducers/workflowIDEReducer";
import type { PackageIDEState } from "./uiReducers/packageIDEReducer";
import type { AiAgentReduxState } from "./entityReducers/aiAgentReducer";
import { type ActionSchemaReduxState } from "./uiReducers/actionSchemaReducer";
import BillingReducer, { type BillingState } from "./billingReducer";

const appReducer = combineReducers({
  ...CE_AppReducer,
  acl: aclReducer,
  auditLogs: auditLogsReducer,
  environments: environmentReducer,
  ai: AIReducer,
  provisioning: ProvisioningReducer,
  billing: BillingReducer,
});

export interface AppState extends CE_AppState {
  acl: AclReduxState;
  auditLogs: AuditLogsReduxState;
  environments: EnvironmentsReduxState;
  ai: AIReduxState;
  provisioning: ProvisioningReduxState;
  entities: CE_AppState["entities"] & {
    packages: PackagesReducerState;
    modules: ModulesReducerState;
    moduleInstances: ModuleInstanceReducerState;
    workflows: WorkflowsReducerState;
    moduleInstanceEntities: ModuleInstanceEntitiesReducerState;
    actions: ActionDataState;
    aiAgents: AiAgentReduxState;
  };
  ui: CE_AppState["ui"] & {
    moduleInstancePane: ModuleInstancePaneState;
    gitExtended: GitExtendedState;
    workflowHistoryPane: WorkflowHistoryPaneState;
    moduleActiveActions: ModuleActiveActionsState;
    workflowPlugin: WorkflowPluginState;
    workflowIDE: WorkflowIDEState;
    packageide: PackageIDEState;
    actionSchema: ActionSchemaReduxState;
  };
  billing: BillingState;
}

export default appReducer;
