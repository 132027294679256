export * from "ce/api/OrganizationApi";
import type { ApiResponse } from "api/ApiResponses";
import type { AxiosPromise } from "axios";
import { OrganizationApi as CE_OrganizationApi } from "ce/api/OrganizationApi";

export interface UpdateOrganizationDomainConfigRequest {
  name: string;
  subdomain: string;
  tenantId: string;
}

export interface CheckSubdomainAvailabilityRequest {
  subdomain: string;
  tenantId: string;
}

export class OrganizationApi extends CE_OrganizationApi {
  static tenantsUrl = "v1/tenants";
  static licenseUrl = `${OrganizationApi.tenantsUrl}/license`;

  static async refreshLicense(): Promise<AxiosPromise<ApiResponse>> {
    return OrganizationApi.put(OrganizationApi.licenseUrl, {
      refreshExistingLicenseAndFlags: true,
    });
  }

  static async validateLicense(
    licenseKey: string,
  ): Promise<AxiosPromise<ApiResponse>> {
    return OrganizationApi.put(OrganizationApi.licenseUrl, {
      key: licenseKey,
    });
  }

  static async validateLicenseForOnboarding(
    licenseKey: string,
  ): Promise<AxiosPromise<ApiResponse>> {
    return OrganizationApi.post(OrganizationApi.licenseUrl, {
      key: licenseKey,
    });
  }

  static async removeLicense(): Promise<AxiosPromise<ApiResponse>> {
    return OrganizationApi.delete(OrganizationApi.licenseUrl);
  }

  static async validateLicenseDryRun(
    licenseKey: string,
  ): Promise<AxiosPromise<ApiResponse>> {
    return OrganizationApi.put(OrganizationApi.licenseUrl, {
      key: licenseKey,
      isDryRun: true,
    });
  }

  /**
   * CHeck if subdomain is available
   *
   * @param subdomain - The subdomain to check
   * @param tenantId - The ID of the tenant
   *
   * @returns A promise that resolves to the API response
   *
   */
  static async checkSubdomainAvailability(
    request: CheckSubdomainAvailabilityRequest,
  ): Promise<AxiosPromise<ApiResponse<boolean>>> {
    return OrganizationApi.get(
      `${OrganizationApi.tenantsUrl}/${request.tenantId}/allowed-subdomain?subdomain=${request.subdomain}`,
    );
  }

  /**
   * Get the license redirect URL for the given plan type
   *
   * @param planType - The type of plan
   * @returns A promise that resolves to the API response
   *
   */
  static async getLicenseRedirectUrl(
    planType: "free" | "business" | "team",
  ): Promise<AxiosPromise<ApiResponse<string>>> {
    const endpoints = {
      free: `${OrganizationApi.licenseUrl}`,
      business: `${OrganizationApi.licenseUrl}/trial`,
      team: `${OrganizationApi.licenseUrl}/trial-ai`,
    };

    return OrganizationApi.post(endpoints[planType], {
      key: planType === "free" ? "" : undefined,
    });
  }
}

export default OrganizationApi;
