import React from "react";
import { Flex, Button, Text } from "@appsmith/ads";
import { createMessage, IN_APP_EMBED_SETTING } from "ee/constants/messages";

const CHROME_EXTENSION_URL =
  "https://chromewebstore.google.com/detail/appsmith-agents/mlmnoikcaeggnjijgimjbiojaeeeifal";

export function ChromeExtensionBanner() {
  return (
    <Flex flexDirection="column" gap="spaces-2">
      <Text kind="body-m">
        {createMessage(IN_APP_EMBED_SETTING.chromeExtensionBannerTitle)}
      </Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="var(--ads-v2-color-fg-muted)" kind="body-s">
          {createMessage(IN_APP_EMBED_SETTING.chromeExtensionBannerDescription)}
        </Text>
        <Button
          endIcon="share-box-line"
          href={CHROME_EXTENSION_URL}
          kind="primary"
          renderAs="a"
          target="_blank"
        >
          {createMessage(IN_APP_EMBED_SETTING.chromeExtensionBannerButton)}
        </Button>
      </Flex>
    </Flex>
  );
}
