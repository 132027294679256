export * from "ce/utils/adminSettingsHelpers";
import { saveAllowed as CE_saveAllowed } from "ce/utils/adminSettingsHelpers";
import type { User } from "constants/userConstants";
import {
  ADMIN_SETTINGS_CATEGORY_ACL_PATH,
  ADMIN_SETTINGS_CATEGORY_AUDIT_LOGS_PATH,
  ADMIN_SETTINGS_CATEGORY_DEFAULT_PATH,
  ADMIN_SETTINGS_CATEGORY_PROFILE_PATH,
} from "constants/routes";
import {
  PERMISSION_TYPE,
  LOGIC_FILTER,
  isPermitted,
} from "ee/utils/permissionHelpers";
import {
  OIDCOAuthURL,
  KeycloakOAuthURL,
  GoogleOAuthURL,
  GithubOAuthURL,
} from "ee/constants/ApiConstants";
import {
  type AdminConfigType,
  type Category,
} from "ee/pages/AdminSettings/config/types";

export const saveAllowed = (
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any,
  isFormLoginEnabled: boolean,
  socialLoginList: string[],
) => {
  const connectedMethodsCount =
    socialLoginList.length + (isFormLoginEnabled ? 1 : 0);

  if (connectedMethodsCount === 1) {
    const checkOidcAuth =
      settings["APPSMITH_OAUTH2_OIDC_CLIENT_ID"] !== "" &&
      socialLoginList.includes("oidc");

    return (
      CE_saveAllowed(settings, isFormLoginEnabled, socialLoginList) ||
      checkOidcAuth ||
      socialLoginList.includes("saml")
    );
  } else {
    return connectedMethodsCount >= 2;
  }
};

export const getDefaultAdminSettingsPath = ({
  isSuperUser = false,
  organizationPermissions = [],
}: {
  isSuperUser: boolean;
  organizationPermissions: string[];
}): string => {
  const redirectToAuditLogs = isPermitted(
    organizationPermissions,
    PERMISSION_TYPE.READ_AUDIT_LOGS,
  );
  const redirectToGroups = isPermitted(
    organizationPermissions,
    [
      PERMISSION_TYPE.ORGANIZATION_READ_PERMISSION_GROUPS,
      PERMISSION_TYPE.ORGANIZATION_READ_USER_GROUPS,
    ],
    LOGIC_FILTER.OR,
  );

  if (isSuperUser) {
    return ADMIN_SETTINGS_CATEGORY_DEFAULT_PATH;
  } else if (!redirectToAuditLogs && redirectToGroups) {
    return ADMIN_SETTINGS_CATEGORY_ACL_PATH;
  } else if (redirectToAuditLogs) {
    return ADMIN_SETTINGS_CATEGORY_AUDIT_LOGS_PATH;
  }

  return ADMIN_SETTINGS_CATEGORY_PROFILE_PATH;
};

export const showAdminSettings = (user?: User): boolean =>
  user?.adminSettingsVisible || false;

export const getLoginUrl = (method: string): string => {
  const urls: Record<string, string> = {
    oidc: OIDCOAuthURL,
    saml: KeycloakOAuthURL,
    google: GoogleOAuthURL,
    github: GithubOAuthURL,
  };

  return urls[method];
};

export const getWrapperCategory = (
  categories: Record<string, AdminConfigType>,
  subCategory: string,
  category: string,
) => {
  return categories[
    ["users", "groups", "roles"].includes(category)
      ? category
      : subCategory ?? category
  ];
};

export const getFilteredUserManagementCategories = (
  categories: Category[],
  showAdminSettings: boolean,
  isSuperUser?: boolean,
) => {
  return categories
    ?.map((category: Category) => {
      if (
        (!isSuperUser &&
          ["groups", "roles"].includes(category.slug) &&
          showAdminSettings) ||
        isSuperUser
      ) {
        return category;
      }

      return null;
    })
    .filter(Boolean) as Category[];
};

export const getFilteredOrgCategories = (
  categories: Category[],
  isAuditLogsEnabled: boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSuperUser?: boolean,
) => {
  return categories
    ?.map((category: Category) => {
      if (category.slug === "audit-logs" && !isAuditLogsEnabled) {
        return null;
      }

      if (
        (!isSuperUser && ["audit-logs"].includes(category.slug)) ||
        isSuperUser
      ) {
        return category;
      }

      return null;
    })
    .filter(Boolean) as Category[];
};
