import React from "react";
import { Checkbox } from "@appsmith/ads";

interface RagSearchAllDocsProps {
  isSearchAll?: boolean;
  setIsSearchAll: (value: boolean) => void;
}

export const RagSearchAllDocs = (props: RagSearchAllDocsProps) => {
  const { isSearchAll, setIsSearchAll } = props;

  return (
    <Checkbox
      isSelected={isSearchAll}
      onChange={(value) => {
        setIsSearchAll(value);
      }}
    >
      Search all documents
    </Checkbox>
  );
};
