/**
 * ModuleInterfaceSagas
 *
 * Purpose:
 * This saga file serves as a bridge between the Community Edition (CE) and Enterprise Edition (EE)
 * module-related functionalities. It provides a clean interface layer that handles all interactions
 * between core widget operations and module-specific features available in the enterprise version.
 */
export * from "ce/sagas/moduleInterfaceSagas";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { HandleModuleWidgetCreationSagaPayload } from "ce/sagas/moduleInterfaceSagas";
import { createUIModuleInstance } from "ee/actions/moduleInstanceActions";
import { ModuleInstanceCreatorType } from "ee/constants/ModuleInstanceConstants";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { getIsModuleFetchingEntities } from "ee/selectors/modulesSelector";
import { getIsPackageEditorInitialized } from "ee/selectors/packageSelectors";
import type { Saga } from "redux-saga";
import { call, put, select, take } from "redux-saga/effects";
import { getCurrentPageId } from "selectors/editorSelectors";

export function* handleModuleWidgetCreationSaga(
  props: HandleModuleWidgetCreationSagaPayload,
) {
  try {
    const { addChildPayload, widgets } = props;
    const isModuleWidget = addChildPayload.type.startsWith("MODULE_WIDGET_");
    const pageId: string = yield select(getCurrentPageId);
    const widget = widgets[addChildPayload.newWidgetId];

    if (isModuleWidget) {
      widgets[addChildPayload.newWidgetId].isCreationPending = true;

      yield put(
        createUIModuleInstance({
          contextId: pageId,
          contextType: ModuleInstanceCreatorType.PAGE,
          widgetName: widget.widgetName,
          widgetId: widget.widgetId,
          parentWidgetId: widget.parentId || "",
          moduleUUID: widget.moduleUUID,
        }),
      );
    }

    return widgets;
  } catch (error) {
    return props.widgets;
  }
}

export function* waitForPackageInitialization(
  saga: Saga,
  action: ReduxAction<unknown>,
) {
  const isPackageEditorInitialized: boolean = yield select(
    getIsPackageEditorInitialized,
  );

  // Wait until the package editor is initialized
  if (!isPackageEditorInitialized) {
    yield take(ReduxActionTypes.INITIALIZE_PACKAGE_EDITOR_SUCCESS);
  }

  // Wait until we're done fetching module entities if applicable
  const isModuleFetchingEntities: boolean = yield select(
    getIsModuleFetchingEntities,
  );

  if (isModuleFetchingEntities) {
    yield take(ReduxActionTypes.FETCH_MODULE_ENTITIES_SUCCESS);
  }

  // Continue yielding
  yield call(saga, action);
}
