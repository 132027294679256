import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export const fetchUpcomingInvoiceSummary = () => ({
  type: ReduxActionTypes.FETCH_UPCOMING_INVOICE_SUMMARY,
});

export const getPricePlansConfig = () => ({
  type: ReduxActionTypes.GET_PRICE_PLANS_CONFIG,
});

export const fetchActiveUsers = () => ({
  type: ReduxActionTypes.FETCH_ACTIVE_USERS,
});

export const refreshLicenseAfterCheckout = () => ({
  type: ReduxActionTypes.REFRESH_LICENSE_AFTER_CHECKOUT,
});
