import React, { useCallback } from "react";
import { Button } from "@appsmith/ads";
import { openCarbonModal } from "ee/actions/aiAgentActions";
import { useDispatch } from "react-redux";

interface RagAddSourcesProps {
  datasourceId?: string;
}

export const RagAddSources = (props: RagAddSourcesProps) => {
  const dispatch = useDispatch();

  const onAddSources = useCallback(() => {
    dispatch(openCarbonModal({ shouldOpen: true }));
  }, [dispatch]);

  if (!props.datasourceId) return null;

  return (
    <Button
      UNSAFE_width="110px"
      kind="secondary"
      onClick={onAddSources}
      startIcon="plus"
    >
      Add sources
    </Button>
  );
};
