import type { IntegrationName } from "@appsmith/carbon-connect";
import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import { createImmerReducer } from "utils/ReducerUtils";

export interface AiAgentReduxState {
  isCreatingAgentInWorkspace: boolean;
  // on click of integration button in carbon that are function-calling based, we want to start a flow
  // for creating datasource and queries, this prop tells which integration is being created
  installingIntegrationName: IntegrationName | null;
  // This prop tells if the carbon modal is open or not. Why can't we put it in the state of the CarbonButton?
  // because the modal can be opened from outside of carbon CarbonButton.
  isCarbonModalOpen: boolean;
  // This prop dicates if FC integrations are enabled or not. For e.g - for new user flow, we show FC integrations and,
  // after the new user flow, we keep it disabled.
  isFCIntegrationsEnabled: boolean;
  isSupportModalOpen: boolean;
}

const initialState: AiAgentReduxState = {
  isCreatingAgentInWorkspace: false,
  installingIntegrationName: null,
  isCarbonModalOpen: false,
  isFCIntegrationsEnabled: false,
  isSupportModalOpen: false,
};

export const handlers = {
  [ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_INIT]: (
    draftState: AiAgentReduxState,
  ) => {
    draftState.isCreatingAgentInWorkspace = true;
  },
  [ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_SUCCESS]: (
    draftState: AiAgentReduxState,
  ) => {
    draftState.isCreatingAgentInWorkspace = false;
  },
  [ReduxActionErrorTypes.CREATE_AI_AGENT_FROM_WORKSPACE_ERROR]: (
    draftState: AiAgentReduxState,
  ) => {
    draftState.isCreatingAgentInWorkspace = false;
  },
  [ReduxActionTypes.CREATE_DATASOURCE_AND_SEARCH_QUERIES_FOR_AI_AGENT_INIT]: (
    draftState: AiAgentReduxState,
    action: ReduxAction<{ integrationName: IntegrationName }>,
  ) => {
    draftState.installingIntegrationName = action.payload.integrationName;
  },
  [ReduxActionTypes.CREATE_DATASOURCE_AND_SEARCH_QUERIES_FOR_AI_AGENT_SUCCESS]:
    (draftState: AiAgentReduxState) => {
      draftState.installingIntegrationName = null;
    },
  [ReduxActionTypes.OPEN_CARBON_MODAL]: (
    draftState: AiAgentReduxState,
    action: ReduxAction<{ shouldOpen: boolean }>,
  ) => {
    draftState.isCarbonModalOpen = action.payload.shouldOpen;

    if (action.payload.shouldOpen === false) {
      draftState.isFCIntegrationsEnabled = false;
    }
  },
  [ReduxActionErrorTypes.CREATE_DATASOURCE_AND_SEARCH_QUERIES_FOR_AI_AGENT_ERROR]:
    (draftState: AiAgentReduxState) => {
      draftState.installingIntegrationName = null;
    },
  [ReduxActionTypes.INSTALLING_INTEGRATION_NAME]: (
    draftState: AiAgentReduxState,
    action: ReduxAction<{ integrationName: IntegrationName }>,
  ) => {
    draftState.installingIntegrationName = action.payload.integrationName;
  },
  [ReduxActionTypes.TOGGLE_FC_INTEGRATIONS]: (
    draftState: AiAgentReduxState,
    action: ReduxAction<{ isEnabled: boolean }>,
  ) => {
    draftState.isFCIntegrationsEnabled = action.payload.isEnabled;
  },
  [ReduxActionTypes.TOGGLE_AI_SUPPORT_MODAL]: (
    draftState: AiAgentReduxState,
  ) => {
    draftState.isSupportModalOpen = !draftState.isSupportModalOpen;
  },
};

const aiAgentReducer = createImmerReducer(initialState, handlers);

export { aiAgentReducer };
