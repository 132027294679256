import Api from "api/Api";
import type { ApiResponse } from "api/ApiResponses";
import type { AxiosPromise } from "axios";

export class BillingApi {
  static tenantsUrl = "v1/tenants";
  static licenseUrl = `${BillingApi.tenantsUrl}/license`;

  static async getUpcomingInvoiceSummary(): Promise<AxiosPromise<ApiResponse>> {
    return Api.get(`${BillingApi.licenseUrl}/upcoming-invoice`);
  }

  static async getPricePlansConfig(): Promise<AxiosPromise<ApiResponse>> {
    return Api.get(`${BillingApi.licenseUrl}/price-plan-config`);
  }

  static async getStripeDashboard(
    redirectUrl?: string,
  ): Promise<AxiosPromise<ApiResponse>> {
    const queryParams = redirectUrl
      ? `?redirectUrl=${encodeURIComponent(redirectUrl)}`
      : "";

    return Api.get(`${BillingApi.licenseUrl}/stripe-dashboard${queryParams}`);
  }

  static async generateCheckoutRequest(): Promise<AxiosPromise<ApiResponse>> {
    return Api.put(`${BillingApi.licenseUrl}/checkout`);
  }

  static async generateTrialRequest(): Promise<AxiosPromise<ApiResponse>> {
    return Api.post(`${BillingApi.licenseUrl}/trial`);
  }

  static async getActiveUsers(): Promise<AxiosPromise<ApiResponse>> {
    return Api.get(`${BillingApi.tenantsUrl}/active-users`);
  }
}

export default BillingApi;
