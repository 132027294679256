import type { AppState } from "ee/reducers";
import { createSelector } from "reselect";

export const getPricePlansInfo = (state: AppState) => state.billing.plansInfo;

export const getPricePlansConfigLoading = (state: AppState) =>
  state.billing.isPricePlansConfigLoading;

export const getActiveUsers = (state: AppState) => state.billing.activeUsers;

export const getUpcomingInvoiceSummaryIsLoading = (state: AppState) =>
  state.billing.isUpcomingInvoiceSummaryLoading;

export const getUpcomingInvoiceSummary = (state: AppState) =>
  state.billing.upcomingInvoiceSummary;

export const getUpcomingInvoiceSummaryPeriodEnd = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary?.periodEnd,
);

export const getInvoiceSummaryLineItems = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary?.lineItems || [],
);

export const getUpcomingInvoiceSummaryTotal = createSelector(
  [getUpcomingInvoiceSummary],
  (upcomingInvoiceSummary) => upcomingInvoiceSummary?.total,
);
