import type { ParentEntityIDETabs } from "ee/reducers/uiReducers/packageIDEReducer";
import { EditorEntityTab } from "IDE/Interfaces/EditorTypes";
import { get, keyBy } from "lodash";
import { getAllJSModules, getAllQueryModules } from "./modulesSelector";
import type { ModuleEntityItem } from "ee/pages/PackageIDE/types";
import { groupAndSortEntitySegmentList } from "IDE/utils/groupAndSortEntitySegmentList";
import {
  ActionUrlIcon,
  JsFileIconV2,
} from "pages/Editor/Explorer/ExplorerIcons";
import { PluginType } from "entities/Plugin";
import { getAssetUrl } from "ee/utils/airgapHelpers";
import {
  isEmbeddedAIDataSource,
  isEmbeddedRestDatasource,
  type Datasource,
} from "entities/Datasource";
import type { AppState } from "ee/reducers";
import { createSelector } from "reselect";
import {
  getCurrentBasePackageId,
  getFirstModule,
  getHasPrivateEntityForPackage,
} from "./packageSelectors";
import type { Module } from "ee/constants/ModuleConstants";
import { getQueryModuleEntityItemUrl } from "ee/pages/PackageIDE/layouts/routers/utils/getQueryModuleEntityItemUrl";
import {
  identifyEntityFromPath,
  type FocusEntityInfo,
} from "navigation/FocusEntity";
import { getJSModuleEntityItemUrl } from "ee/pages/PackageIDE/layouts/routers/utils/getJSModuleEntityItemUrl";
import { getValues } from "ee/pages/PackageIDE/layouts/hooks/useLastVisitedModule";
import history from "utils/history";
import { getEntityURL } from "ee/pages/PackageIDE/utils/getEntityUrl";
import { selectFeatureFlags } from "./featureFlagsSelectors";

export const getPackageIDETabs = (state: AppState) => state.ui.packageide.tabs;

export const getAllQueryTabs = createSelector(
  getCurrentBasePackageId,
  getPackageIDETabs,
  (basePackageId: string, tabs: ParentEntityIDETabs): string[] =>
    get(tabs, [basePackageId, EditorEntityTab.QUERIES], []),
);

export const getAllJSTabs = createSelector(
  getCurrentBasePackageId,
  getPackageIDETabs,
  (basePackageId: string, tabs: ParentEntityIDETabs): string[] =>
    get(tabs, [basePackageId, EditorEntityTab.JS], []),
);

export const selectJSModuleSegmentEditorTabs = (state: AppState) => {
  const items = getJSModulesSegmentItems(state);
  const tabs = getAllJSTabs(state);

  const keyedItems = keyBy(items, "key");

  return tabs
    .map((tab) => {
      return keyedItems[tab];
    })
    .filter(Boolean);
};

export const selectQueryModuleSegmentEditorTabs = (state: AppState) => {
  const items = getQueryModulesSegmentItems(state);
  const tabs = getAllQueryTabs(state);

  const keyedItems = keyBy(items, "key");

  return tabs.map((tab) => keyedItems[tab]).filter(Boolean);
};

export const selectDSIdToNameMap = createSelector(
  (state: AppState) => state.entities.datasources.list,
  (datasources) => {
    return datasources.reduce(
      (acc, datasource) => {
        acc[datasource.id] = datasource.name;

        return acc;
      },
      {} as Record<string, string>,
    );
  },
);

export const getDS = (
  state: AppState,
  datasourceId: string,
): Datasource | undefined =>
  state.entities.datasources.list.find(
    (datasource) => datasource.id === datasourceId,
  );

export const getQueryModulesSegmentItems = createSelector(
  getAllQueryModules,
  (state: AppState) => state.entities.plugins.list,
  selectDSIdToNameMap,
  (state: AppState) => state,
  (modules, plugins, datasourceIdToNameMap, state) => {
    const pluginGroups = keyBy(plugins, "id");
    const items: ModuleEntityItem[] = modules.map((module: Module) => {
      let group;
      const iconUrl = getAssetUrl(pluginGroups[module.pluginId]?.iconLocation);
      const datasource = getDS(state, module?.datasourceId || "");

      if (module.pluginType === PluginType.API) {
        group =
          datasource && isEmbeddedRestDatasource(datasource)
            ? "APIs"
            : datasourceIdToNameMap[datasource?.id || ""] ?? "APIs";
      } else if (module.pluginType === PluginType.AI) {
        group =
          datasource && isEmbeddedAIDataSource(datasource)
            ? "AI Queries"
            : datasourceIdToNameMap[datasource?.id || ""] ?? "AI Queries";
      } else {
        group = datasource?.name ?? datasourceIdToNameMap[datasource?.id || ""];
      }

      return {
        icon: ActionUrlIcon(iconUrl, "16", "16"),
        title: module.name,
        key: module.actionId,
        moduleId: module.baseId,
        type: module.pluginType,
        group,
        userPermissions: module.userPermissions,
      };
    });

    return items;
  },
);

export const getJSModulesSegmentItems = createSelector(
  getAllJSModules,
  (modules) => {
    const items: ModuleEntityItem[] = modules.map((module) => ({
      icon: JsFileIconV2(),
      title: module.name,
      key: module.actionId,
      moduleId: module.baseId,
      type: PluginType.JS,
      userPermissions: module.userPermissions,
    }));

    return items;
  },
);

export const selectQueryModulesSegmentEditorList = createSelector(
  getQueryModulesSegmentItems,
  (items: ModuleEntityItem[]) => {
    return groupAndSortEntitySegmentList(items);
  },
);

export const selectJSModulesSegmentEditorList = createSelector(
  getJSModulesSegmentItems,
  (items: ModuleEntityItem[]) => {
    return groupAndSortEntitySegmentList(items);
  },
);

export const getLastQueryModuleTab = createSelector(
  selectQueryModuleSegmentEditorTabs,
  (tabs) => {
    if (tabs.length) {
      const url = getQueryModuleEntityItemUrl(tabs[tabs.length - 1]);
      const urlWithoutQueryParams = url.split("?")[0];

      return identifyEntityFromPath(urlWithoutQueryParams);
    }
  },
);

export const getLastJSModuleTab = (
  state: AppState,
): FocusEntityInfo | undefined => {
  const tabs = selectJSModuleSegmentEditorTabs(state);

  if (tabs.length) {
    const url = getJSModuleEntityItemUrl(tabs[tabs.length - 1]);
    const urlWithoutQueryParams = url.split("?")[0];

    return identifyEntityFromPath(urlWithoutQueryParams);
  }
};

export const getLastVisitedModuleUrl = createSelector(
  getCurrentBasePackageId,
  getFirstModule,
  (state) => state.entities.plugins.list,
  selectFeatureFlags,
  getHasPrivateEntityForPackage,
  (basePackageId = "", module, plugins, featureFlags, hasPrivateEntity) => {
    if (
      !(
        featureFlags.release_segmented_package_explorer_enabled &&
        !hasPrivateEntity
      )
    ) {
      return;
    }

    const values = getValues() || {};
    const goToModule = values[basePackageId] ? values[basePackageId] : module;
    const pluginGroups = keyBy(plugins, "id");
    const currentPath = window.location.pathname;

    if (!goToModule) return history.replace(`${currentPath}/queries`);

    const plugin = pluginGroups[goToModule.pluginId];

    const entityUrl = getEntityURL({
      baseId: goToModule.actionId,
      plugin,
      baseModuleId: goToModule.baseId,
    });

    return history.replace(entityUrl);
  },
);
