export * from "ce/constants/PackageConstants";
import type { Package as CE_Package } from "ce/constants/PackageConstants";

export enum PACKAGE_TYPE {
  UI = "ui",
  CODE = "code",
}

export type Package = CE_Package & {
  lastDeployedAt?: string;
  lastPublishedAt: string;
  lastPublishedBy: string;
  lastPublishedVersion: string;
  originPackageId: string;
  nextVersion: string;
  activeVersion?: string;
  versions?: Array<string>;
  packageUUID: string;
  type?: PACKAGE_TYPE;
  hasPrivateEntity?: boolean;
};

export type PackageMetadata = Pick<
  Package,
  | "id"
  | "name"
  | "workspaceId"
  | "icon"
  | "color"
  | "modifiedAt"
  | "modifiedBy"
  | "userPermissions"
  | "lastPublishedAt"
  | "gitArtifactMetadata"
>;

export const DEFAULT_PACKAGE_COLOR = "#9747FF1A";
export const DEFAULT_PACKAGE_ICON = "package";
export const DEFAULT_PACKAGE_PREFIX = "Untitled Package ";
