import React from "react";
import { useSelector } from "react-redux";

import {
  isOrganizationLoading,
  isWithinAnOrganization,
} from "ee/selectors/organizationSelectors";
import { useLocation } from "react-router-dom";
import {
  BASE_SIGNUP_URL,
  PAGE_NOT_FOUND_URL,
  SIGN_UP_URL,
  RESET_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
  USER_AUTH_URL,
  USERS_URL,
  AUTH_LOGIN_URL,
  BASE_LOGIN_URL,
} from "constants/routes";
import {
  VERIFY_EMAIL_URL,
  VERIFICATION_ERROR_URL,
  VERIFICATION_PENDING_URL,
} from "ee/constants/routes/appRoutes";

const PATHS_TO_CHECK = [
  USER_AUTH_URL,
  USERS_URL,
  BASE_LOGIN_URL,
  BASE_SIGNUP_URL,
  AUTH_LOGIN_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  SIGN_UP_URL,
  PAGE_NOT_FOUND_URL,
  VERIFY_EMAIL_URL,
  VERIFICATION_ERROR_URL,
  VERIFICATION_PENDING_URL,
];

const checkNonLoginPageIsWithinOrg = (Component: React.ComponentType) => {
  return function Wrapped(props: React.ComponentProps<typeof Component>) {
    const location = useLocation();
    const isLoading = useSelector(isOrganizationLoading);

    const isWithinOrganization = useSelector(isWithinAnOrganization);
    const isPathToCheck = PATHS_TO_CHECK.includes(location.pathname);

    let shouldRedirect = false;

    if (!isWithinOrganization && isPathToCheck && !isLoading) {
      shouldRedirect = true;
    }

    if (shouldRedirect) {
      const url = new URL(window.location.href);

      url.hostname = `login.${url.hostname.split(".").slice(1).join(".")}`;
      // remove the path from the url
      url.pathname = "";
      window.location.href = url.toString();

      return null;
    }

    return <Component {...props} />;
  };
};

export { checkNonLoginPageIsWithinOrg };
