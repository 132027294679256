import type { ApiResponse } from "api/ApiResponses";
import type { OrganizationReduxState } from "ee/reducers/organizationReducer";
import BillingApi from "ee/api/BillingApi";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import type {
  CheckoutResponse,
  DashboardRespsonse,
  UpcomingInvoiceSummaryResponseType,
} from "ee/reducers/billingReducer.types";
import type { License } from "ee/reducers/organizationReducer.types";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { validateResponse } from "sagas/ErrorSagas";
import {
  RESTART_POLL_INTERVAL,
  RESTART_POLL_TIMEOUT,
} from "ee/constants/organizationConstants";
import OrganizationApi from "ee/api/OrganizationApi";
import { fetchFeatureFlags } from "./userSagas";
import { LICENSE_TYPE } from "ee/pages/Billing/Types/types";
import { toast } from "@appsmith/ads";
import {
  createMessage,
  TRIAL_LICENSE_ACTIVATED_SUCCESSFULLY,
} from "ee/constants/messages";

export function* fetchUpcomingInvoiceSummarySaga() {
  try {
    const response: ApiResponse<UpcomingInvoiceSummaryResponseType> =
      yield call(BillingApi.getUpcomingInvoiceSummary);

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.FETCH_UPCOMING_INVOICE_SUMMARY_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_UPCOMING_INVOICE_SUMMARY_ERROR,
      payload: { error },
    });
  }
}

export function* getPricePlansConfigSaga() {
  try {
    const response: ApiResponse<OrganizationReduxState<License>> = yield call(
      BillingApi.getPricePlansConfig,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.GET_PRICE_PLANS_CONFIG_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GET_PRICE_PLANS_CONFIG_ERROR,
      payload: {
        error: error,
      },
    });
  }
}

export function* goToStripeDashboardSaga() {
  try {
    const redirectUrl = window.location.href;

    const response: ApiResponse<DashboardRespsonse> = yield call(
      BillingApi.getStripeDashboard,
      redirectUrl,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      window.open(response.data.customerDashboardUrl, "_blank");
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GO_TO_STRIPE_DASHBOARD_ERROR,
      payload: {
        error: error,
      },
    });
  }
}

export function* checkoutRequestSaga() {
  try {
    const response: ApiResponse<CheckoutResponse> = yield call(
      BillingApi.generateCheckoutRequest,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      window.location.href = response.data.checkoutUrl;
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CHECKOUT_REQUEST_ERROR,
      payload: {
        error: error,
      },
    });
  }
}

export function* trialRequestSaga() {
  try {
    const response: ApiResponse<OrganizationReduxState<License>> = yield call(
      BillingApi.generateTrialRequest,
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.TRIAL_REQUEST_SUCCESS,
        payload: response.data,
      });
      toast.show(createMessage(TRIAL_LICENSE_ACTIVATED_SUCCESSFULLY), {
        kind: "success",
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.TRIAL_REQUEST_ERROR,
      payload: {
        error: error,
      },
    });
  }
}

export function* fetchActiveUsersSaga() {
  try {
    const response: ApiResponse<number> = yield call(BillingApi.getActiveUsers);

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.FETCH_ACTIVE_USERS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_ACTIVE_USERS_ERROR,
      payload: {
        error: error,
      },
    });
  }
}

export function* RefreshLicenseAndFlagsServerPoll() {
  let pollCount = 0;
  const maxPollCount = RESTART_POLL_TIMEOUT / RESTART_POLL_INTERVAL;

  while (pollCount < maxPollCount) {
    pollCount++;
    yield delay(RESTART_POLL_INTERVAL);
    try {
      const response: ApiResponse<OrganizationReduxState<License>> = yield call(
        OrganizationApi.refreshLicense,
      );
      const isValidResponse: boolean = yield validateResponse(response);

      if (isValidResponse) {
        yield call(fetchFeatureFlags);
        yield put({
          type: ReduxActionTypes.REFRESH_LICENSE_SUCCESS,
          payload: response.data,
        });

        if (
          response.data?.organizationConfiguration?.license?.type ===
          LICENSE_TYPE.PAID
        ) {
          window.location.href = "/settings/license";
        }
      } else {
        yield put({
          type: ReduxActionErrorTypes.REFRESH_LICENSE_ERROR,
          payload: {
            error: response.responseMeta.error,
          },
        });
      }
    } catch (e) {}
  }

  yield put({
    type: ReduxActionErrorTypes.RESTART_SERVER_ERROR,
  });
}

export default function* billingSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.FETCH_UPCOMING_INVOICE_SUMMARY,
      fetchUpcomingInvoiceSummarySaga,
    ),
    takeLatest(
      ReduxActionTypes.GET_PRICE_PLANS_CONFIG,
      getPricePlansConfigSaga,
    ),
    takeLatest(
      ReduxActionTypes.GO_TO_STRIPE_DASHBOARD,
      goToStripeDashboardSaga,
    ),
    takeLatest(ReduxActionTypes.CHECKOUT_REQUEST_INIT, checkoutRequestSaga),
    takeLatest(ReduxActionTypes.TRIAL_REQUEST_INIT, trialRequestSaga),
    takeLatest(ReduxActionTypes.FETCH_ACTIVE_USERS, fetchActiveUsersSaga),
    takeLatest(
      ReduxActionTypes.REFRESH_LICENSE_AFTER_CHECKOUT,
      RefreshLicenseAndFlagsServerPoll,
    ),
  ]);
}
