import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, getFormValues } from "redux-form";
import { get } from "lodash";

const CONTAINS_SELECTED_DOCUMENTS_FIELD =
  "actionConfiguration.formData.aiChatAssistant.input.containsSelectedDocuments";

export const useContainsSelectedDocuments = (
  formName: string,
): [boolean, (value: boolean) => void] => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => getFormValues(formName)(state));

  const containsSelectedDocuments = get(
    formData,
    CONTAINS_SELECTED_DOCUMENTS_FIELD,
    false,
  );

  const setContainsSelectedDocuments = useCallback(
    (value: boolean) => {
      if (value === containsSelectedDocuments) return;

      dispatch(change(formName, CONTAINS_SELECTED_DOCUMENTS_FIELD, value));
    },
    [dispatch, formName, containsSelectedDocuments],
  );

  return [containsSelectedDocuments, setContainsSelectedDocuments];
};
