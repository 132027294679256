import React, { useCallback, useEffect, useState } from "react";
import type { ButtonProps } from "@appsmith/ads";
import { Divider, Flex, Icon, Text, Button, Spinner } from "@appsmith/ads";
import * as Sentry from "@sentry/react";
import log from "loglevel";
import { useDispatch, useSelector } from "react-redux";

import {
  CLOUD_BILLING_CHOOSE_PLAN_BUTTONS,
  createMessage,
  ORG_UPDATE_LICENSE_HEADER,
  PER_MONTH_PER_USER,
} from "ee/constants/messages";
import {
  UserWelcomeScreenBannerHeader,
  UserWelcomeScreenActionContainer,
  UserWelcomeScreenContent,
  UserWelcomeScreenTextBanner,
  UserWelcomeScreenWrapper,
} from "pages/setup/common";
import WelcomeBackground from "pages/setup/WelcomeBackground";
import { getIsAiAgentFlowEnabled } from "ee/selectors/aiAgentSelectors";
import { OrganizationApi } from "ee/api/OrganizationApi";
import { ContactUsButton } from "ee/pages/Billing/Components/BillingCloud/ContactUsButton";
import { getPricePlansConfig } from "ee/actions/billingActions";
import { getPricePlansInfo } from "ee/selectors/billingSelectors";
import type { PricePlansConfigResponseType } from "ee/reducers/billingReducer.types";

interface Plan {
  name: string;
  slug: string;
  price: ((price?: number) => number) | null;
  button?: React.ReactNode;
  buttonText?: string;
  buttonKind?: ButtonProps["kind"];
  buttonOnClick?: ButtonProps["onClick"];
  features: { text: string; icon?: string; subText?: string }[];
  footerText?: string;
}

const planMap = new Map<string, Plan>([
  [
    "free",
    {
      slug: "free",
      name: "Free",
      price: () => 0,
      buttonText: createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.free),
      buttonKind: "secondary",
      features: [
        {
          text: "Upto 5 users",
          icon: "check-line",
        },
        {
          text: "5 workspaces",
          icon: "check-line",
        },
        {
          text: "Version control with Git",
          subText: "3 repos",
          icon: "check-line",
        },
        {
          text: "Google SSO",
          icon: "check-line",
        },
        {
          text: "3 standard roles for access control",
          icon: "check-line",
        },
        {
          text: "Public apps",
          icon: "check-line",
        },
        {
          text: "Community support",
          icon: "check-line",
        },
      ],
    },
  ],
  [
    "business",
    {
      slug: "business",
      name: "Business",
      price: (price?: number) => price || 0,
      buttonText: createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.business),
      buttonKind: "primary",
      features: [
        {
          text: "Everything in Free, plus",
        },
        {
          text: "Up to 99 users",
          icon: "check-line",
        },
        {
          text: "Unlimited environments, Git repos, and workspaces",
          icon: "check-line",
        },
        {
          text: "Workflows",
          icon: "check-line",
        },
        {
          text: "Reusable packages",
          icon: "check-line",
        },
        {
          text: "Audit logs",
          icon: "check-line",
        },
        {
          text: `Remove "Powered by Appsmith" branding`,
          icon: "check-line",
        },
        {
          text: "Email/chat support",
          icon: "check-line",
        },
        {
          text: "Custom roles and access controls",
          icon: "check-line",
        },
      ],
      footerText: "No credit card required",
    },
  ],
]);

const aiPlanMap = new Map<string, Plan>([
  [
    "team",
    {
      slug: "team",
      name: "Team",
      price: (price?: number) => price || 0,
      buttonText: createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.team),
      buttonKind: "primary",
      features: [
        {
          text: "Upto 99 users",
          icon: "check-line",
        },
        {
          text: "1GB RAG with Files, Websites, & Cloud Storage",
          icon: "check-line",
        },
        {
          text: "Function calls to any API & Database",
          icon: "check-line",
        },
        {
          text: "Google SSO",
          icon: "check-line",
        },
        {
          text: "User Groups & Roles",
          icon: "check-line",
        },
        {
          text: "Embed Anywhere",
          icon: "check-line",
        },
        {
          text: "Custom Branding",
          icon: "check-line",
        },
      ],
      footerText: "No credit card required",
    },
  ],
  [
    "enterprise",
    {
      slug: "enterprise",
      name: "Enterprise",
      price: null,
      button: (
        <ContactUsButton
          // passing className empty because in ContactUsButton, we have a margin bottom
          // and we don't want that on ChoosePlan page
          className=""
          text={createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.enterprise)}
        />
      ),
      features: [
        {
          text: "Everything in Team, plus",
        },
        {
          text: "Unlimited users",
          icon: "check-line",
        },
        {
          text: "Unlimited RAG",
          icon: "check-line",
        },
        {
          text: "Self-hosting / Managed hosting",
          icon: "check-line",
        },
        {
          text: "SSO (OIDC/SAML)",
          icon: "check-line",
        },
        {
          text: "Audit Logs",
          icon: "check-line",
        },
        {
          text: "Dedicated Support & SLAs",
          icon: "check-line",
        },
        {
          text: "AI Solutions Expert",
          icon: "check-line",
        },
      ],
      footerText: "\u200B",
    },
  ],
]);

const PlanCard = ({
  plan,
  pricePlansConfig,
}: {
  plan: Plan;
  pricePlansConfig: PricePlansConfigResponseType;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const planPrice =
    plan.price !== null
      ? plan.price(pricePlansConfig.pricePerSeat)
      : plan.price;

  const priceFgColor =
    planPrice !== null && planPrice > 0
      ? "var(--ads-v2-color-fg-brand)"
      : "var(--ads-v2-color-fg)";

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await OrganizationApi.getLicenseRedirectUrl(
        plan.slug as "free" | "business" | "team",
      );
      const redirectUrl = response.data as unknown as string;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      log.error("Error getting license redirect URL:", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  }, [plan]);

  return (
    <Flex
      backgroundColor={"var(--ads-v2-color-bg)"}
      border={"1px solid"}
      borderColor={"var(--ads-v2-color-border)"}
      borderRadius={"var(--ads-v2-border-radius)"}
      flexDirection={"column"}
      gap={"spaces-6"}
      padding={"spaces-7"}
      width={"300px"}
    >
      <Flex flexDirection={"column"} gap={"spaces-2"}>
        <Text kind="heading-s">{plan.name}</Text>
        <Flex alignItems={"center"} flexDirection={"row"} gap={"spaces-2"}>
          {plan.price !== null ? (
            <>
              <Text color={priceFgColor} kind="heading-m">
                ${planPrice}
              </Text>
              <Text color={priceFgColor} kind="body-s">
                {createMessage(PER_MONTH_PER_USER)}
              </Text>
            </>
          ) : (
            <Text kind="heading-m">&#8203;</Text>
          )}
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection={"column"} gap={"spaces-6"}>
        {plan.features.map((feature) => (
          <Flex
            alignItems={"center"}
            flexDirection={"row"}
            gap={"spaces-3"}
            key={feature.text}
          >
            {/* Icon */}
            {feature.icon && <Icon name={feature.icon} />}
            <Flex flexDirection={"column"}>
              {/* Text */}
              <Text kind="body-m">{feature.text}</Text>
              {/* Subtext */}
              {feature.subText && (
                <Text color={"var(--ads-v2-color-fg-subtle)"} kind="body-s">
                  {feature.subText}
                </Text>
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
      {/* Footer */}
      <Flex
        flexDirection={"column"}
        gap={"spaces-3"}
        style={{ marginTop: "auto" }}
      >
        {/* Button */}
        {plan.button ? (
          plan.button
        ) : (
          <Button
            isLoading={isLoading}
            kind={plan.buttonKind}
            onClick={plan.buttonOnClick ?? handleClick}
            size="md"
          >
            {plan.buttonText}
          </Button>
        )}
        {/* Footer text */}
        {plan.footerText && (
          <Text
            className="text-center"
            color={"var(--ads-v2-color-fg-subtle)"}
            kind="body-s"
          >
            {plan.footerText}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

const ChoosePlan = () => {
  const dispatch = useDispatch();
  const pricePlansConfig = useSelector(getPricePlansInfo);
  const isAiAgentFlowEnabled = useSelector(getIsAiAgentFlowEnabled);

  const _planMap = isAiAgentFlowEnabled ? aiPlanMap : planMap;

  useEffect(function fetchPriceConfig() {
    dispatch(getPricePlansConfig());
  }, []);

  useEffect(
    function loadChiliPiperScript() {
      if (!isAiAgentFlowEnabled) return;

      const script = document.createElement("script");

      script.src =
        "https://appsmith.chilipiper.com/concierge-js/cjs/concierge.js";
      script.crossOrigin = "anonymous";
      script.type = "text/javascript";
      document.head.appendChild(script);
    },
    [isAiAgentFlowEnabled],
  );

  if (!pricePlansConfig.pricePerSeat) return <Spinner size="lg" />;

  return (
    <UserWelcomeScreenWrapper data-testid={"welcome-page"}>
      <UserWelcomeScreenContent>
        <UserWelcomeScreenTextBanner>
          <UserWelcomeScreenBannerHeader className="!text-2xl">
            {createMessage(ORG_UPDATE_LICENSE_HEADER)}
          </UserWelcomeScreenBannerHeader>
          <UserWelcomeScreenActionContainer>
            <Flex flexDirection={"row"} gap={"spaces-7"}>
              {Array.from(_planMap.entries()).map(([key, plan]) => (
                <PlanCard
                  key={key}
                  plan={plan}
                  pricePlansConfig={pricePlansConfig}
                />
              ))}
            </Flex>
          </UserWelcomeScreenActionContainer>
        </UserWelcomeScreenTextBanner>
        <WelcomeBackground />
      </UserWelcomeScreenContent>
    </UserWelcomeScreenWrapper>
  );
};

export default ChoosePlan;
