import { generateReactKey } from "utils/generators";

export const MAIN_MODULE_CONTAINER_WIDGET_ID = "module-container-0";
export const MAIN_MODULE_CANVAS_WIDGET_ID = "module-canvas-0";
// TODO(Ashit): Needs to be imported from WidgetsContant but using it directly here creates a circular dependency.
// Fix the circular dependency and then remove this.
const DEFAULT_GRID_ROW_HEIGHT = 10;

function createBaseModuleDSL() {
  const MAIN_CANVAS = {
    widgetName: "MainContainer",
    backgroundColor: "none",
    rightColumn: 1242,
    snapColumns: 64,
    detachFromLayout: true,
    widgetId: "0",
    topRow: 0,
    bottomRow: 1380,
    containerStyle: "none",
    snapRows: 124,
    parentRowSpace: 1,
    type: "CANVAS_WIDGET",
    canExtend: true,
    version: 91,
    minHeight: 1292,
    dynamicTriggerPathList: [],
    parentColumnSpace: 1,
    dynamicBindingPathList: [],
    leftColumn: 0,
    dragDisabled: true,
  };

  const MODULE_CONTAINER_WIDGET = {
    needsErrorInfo: false,
    boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    mobileBottomRow: 10,
    widgetName: "ModuleContainer",
    borderColor: "#E0DEDE",
    isCanvas: true,
    topRow: 3, // 3 is the min rows to show the container name.
    bottomRow: MAIN_CANVAS.bottomRow / DEFAULT_GRID_ROW_HEIGHT,
    parentRowSpace: 10,
    type: "MODULE_CONTAINER_WIDGET",
    mobileRightColumn: 24,
    shouldScrollContents: true,
    animateLoading: true,
    parentColumnSpace: 16.609375,
    dynamicTriggerPathList: [],
    leftColumn: 0,
    enhancements: true,
    dynamicBindingPathList: [
      {
        key: "borderRadius",
      },
      {
        key: "boxShadow",
      },
    ],
    children: [],
    borderWidth: "1",
    key: generateReactKey(),
    flexVerticalAlignment: "stretch",
    rightColumn: 64,
    backgroundColor: "transparent",
    dynamicHeight: "FIXED",
    widgetId: MAIN_MODULE_CONTAINER_WIDGET_ID,
    containerStyle: "card",
    minWidth: 450,
    isVisible: true,
    version: 1,
    parentId: MAIN_CANVAS.widgetId, // "0"
    isLoading: false,
    renderMode: "CANVAS",
    mobileTopRow: 0,
    responsiveBehavior: "fill",
    borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    mobileLeftColumn: 0,
    maxDynamicHeight: 9000,
    minDynamicHeight: 10,
    dragDisabled: true,
    suppressAutoComplete: true,
    suppressDebuggerError: true,
    isDeletable: false,
  };

  const MODULE_CONTAINER_CANVAS_WIDGET = {
    needsErrorInfo: false,
    boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    mobileBottomRow: 100,
    widgetName: "ModuleContainerCanvas",
    topRow: 0,
    bottomRow: 730,
    parentRowSpace: 1,
    type: "CANVAS_WIDGET",
    canExtend: false,
    minHeight: 100,
    mobileRightColumn: 403.5,
    parentColumnSpace: 1,
    leftColumn: 0,
    showOnboardingText: true,
    dynamicBindingPathList: [
      {
        key: "borderRadius",
      },
      {
        key: "boxShadow",
      },
    ],
    children: [],
    key: generateReactKey(),
    rightColumn: 403.5,
    detachFromLayout: true,
    dynamicHeight: "AUTO_HEIGHT",
    widgetId: MAIN_MODULE_CANVAS_WIDGET_ID,
    containerStyle: "none",
    minWidth: 450,
    isVisible: true,
    version: 1,
    parentId: MAIN_MODULE_CONTAINER_WIDGET_ID,
    renderMode: "CANVAS",
    isLoading: false,
    mobileTopRow: 0,
    responsiveBehavior: "fill",
    borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
    mobileLeftColumn: 0,
    maxDynamicHeight: 9000,
    minDynamicHeight: 4,
    flexLayers: [],
  };

  return {
    ...MAIN_CANVAS,
    children: [
      {
        ...MODULE_CONTAINER_WIDGET,
        children: [
          {
            ...MODULE_CONTAINER_CANVAS_WIDGET,
          },
        ],
      },
    ],
  };
}

export default createBaseModuleDSL;
