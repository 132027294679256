import React, { useCallback, useState } from "react";
import {
  createMessage,
  ADD_LICENSE,
  ADD_KEY,
  UPGRADE_TEXT,
} from "ee/constants/messages";
import { Button } from "@appsmith/ads";
import { LicenseForm } from "../LicenseForm";
import UpgradePlanModal from "ee/pages/Billing/Components/Modals/UpgradePlanModal";
import { useIsCloudBillingEnabled } from "hooks";

interface LicenseAddFormProps {
  showLicenseForm: boolean;
  setShowLicenseForm: (show: boolean) => void;
}

export default function LicenseAddForm({
  setShowLicenseForm,
  showLicenseForm,
}: LicenseAddFormProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isCloudBillingEnabled = useIsCloudBillingEnabled();

  const handleClick = useCallback(() => {
    if (isCloudBillingEnabled) {
      setIsModalOpen(true);
    } else {
      setShowLicenseForm(true);
    }
  }, [isCloudBillingEnabled, setShowLicenseForm]);

  return (
    <>
      {!showLicenseForm && (
        <div>
          <Button
            className="w-2/5 mt-4"
            data-testid="t--activate-instance-btn-paid"
            isLoading={isModalOpen}
            kind={isCloudBillingEnabled ? "primary" : "secondary"}
            onClick={handleClick}
            size="md"
          >
            {isCloudBillingEnabled
              ? createMessage(UPGRADE_TEXT)
              : createMessage(ADD_LICENSE)}
          </Button>
        </div>
      )}
      {showLicenseForm && (
        <div className="mt-4">
          <LicenseForm
            isModal={false}
            isUpdate={false}
            placeholder={createMessage(ADD_KEY)}
          />
        </div>
      )}
      <UpgradePlanModal
        isModalOpen={isModalOpen}
        onOpenChange={setIsModalOpen}
      />
    </>
  );
}
