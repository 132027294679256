import React, { Suspense, lazy, Component } from "react";
import { Button, Flex } from "@appsmith/ads";
import { get } from "lodash";
import ErrorBoundary from "components/editorComponents/ErrorBoundry";
import AppsmithLogo from "assets/images/appsmith_logo_square.png";
import { getDatasource, getPlugins } from "ee/selectors/entitiesSelector";
import { fetchAccessToken } from "./RagApiRequests";
import { connect } from "react-redux";
import { getAppsmithConfigs } from "ee/configs";
import type { AppState } from "ee/reducers";
import type {
  Integration,
  EmbeddingGenerators,
  FunctionCallingIntegration,
} from "@appsmith/carbon-connect";
import "./carbon-modal.css";
import { IntegrationName } from "@appsmith/carbon-connect";
import type { Plugin } from "entities/Plugin";
import {
  createDatasourceAndSearchQueriesForAiAgent,
  openCarbonModal,
} from "ee/actions/aiAgentActions";
import { getIsFCIntegrationsEnabled } from "ee/selectors/aiAgentSelectors";
import store from "store";
import { setPluginActionEditorDebuggerState } from "PluginActionEditor/store/pluginActionEditorActions";

const CarbonConnect = lazy(async () => import("./CarbonConnect"));
const { carbonApiBasePath } = getAppsmithConfigs();

interface CarbonButtonProps {
  datasourceId: string;
  workspaceId: string;
  integrations?: Integration[];
  embeddingModel?: EmbeddingGenerators;
  onModalStateChange?: (value: boolean) => void;
  plugins?: Plugin[];
  createDatasourceAndSearchQueriesForAiAgent?: typeof createDatasourceAndSearchQueriesForAiAgent;
  installingIntegrationName?: IntegrationName | null;
  isCarbonModalOpen?: boolean;
  openCarbonModal?: typeof openCarbonModal;
  isFCIntegrationEnabled?: boolean;
}

const loadingError = <div>Failed to load CarbonConnect</div>;

const fallbackButton = (
  <Button isLoading size="md" startIcon="add-more">
    Add data
  </Button>
);

interface CarbonButtonState {
  isIntegrationsLoading: boolean;
}

const FUNCTION_CALLING_INTEGRATIONS: FunctionCallingIntegration[] = [
  {
    id: IntegrationName.ZENDESK,
    name: "Zendesk",
    onClick() {
      store.dispatch(
        createDatasourceAndSearchQueriesForAiAgent({
          integrationName: this.id,
          pluginName: "zendesk",
          command: "ZENDESK_SEARCH_ALL_RECORDS",
          query: "{{this.params.search_query}}",
        }),
      );
    },
  },
  {
    id: IntegrationName.SALESFORCE,
    name: "Salesforce",
    onClick() {
      store.dispatch(
        createDatasourceAndSearchQueriesForAiAgent({
          integrationName: this.id,
          pluginName: "salesforce",
          command: "SALESFORCE_WRITE_SOQL_QUERY",
          query: "{{this.params.soql_query}}",
        }),
      );
    },
  },
  {
    id: IntegrationName.SLACK,
    name: "Slack",
    onClick() {
      store.dispatch(
        createDatasourceAndSearchQueriesForAiAgent({
          integrationName: this.id,
          pluginName: "slack",
          command: "SLACK_SEARCH_MESSAGES",
          query: "{{this.params.search_term}}",
        }),
      );
    },
  },
  {
    id: IntegrationName.JIRA,
    name: "Jira",
    onClick() {
      store.dispatch(
        createDatasourceAndSearchQueriesForAiAgent({
          integrationName: this.id,
          pluginName: "jira",
          command: "JIRA_SEARCH_BY_JQL",
          query: "{{this.params.jql_query}}",
        }),
      );
    },
  },
];

class _CarbonButton extends Component<CarbonButtonProps, CarbonButtonState> {
  state = {
    isIntegrationsLoading: false,
    isCarbonModalOpen: false,
  };

  private onCarbonModalOpen = async () => {
    const { onModalStateChange } = this.props;

    this.props.openCarbonModal?.({ shouldOpen: true });

    onModalStateChange && onModalStateChange(true);
  };

  render() {
    const {
      datasourceId,
      embeddingModel,
      isFCIntegrationEnabled,
      onModalStateChange,
      workspaceId,
    } = this.props;

    return (
      <ErrorBoundary fallback={loadingError}>
        <Suspense fallback={fallbackButton}>
          <Flex alignItems="center" gap="spaces-3">
            <Button
              isLoading={this.state.isIntegrationsLoading}
              kind="secondary"
              onClick={this.onCarbonModalOpen}
              size="md"
            >
              Add sources
            </Button>
          </Flex>
          {this.props.isCarbonModalOpen && (
            <CarbonConnect
              apiURL={carbonApiBasePath}
              backButtonText="Close"
              brandIcon={AppsmithLogo}
              embeddingModel={embeddingModel}
              enabledFCIntegrations={
                isFCIntegrationEnabled ? FUNCTION_CALLING_INTEGRATIONS : []
              }
              enabledIntegrations={this.props.integrations}
              entryPoint="INTEGRATION_LIST"
              installingIntegrationId={this.props.installingIntegrationName}
              onSuccess={() => {
                // isFCIntegrationEnable is only true for the new user flow, reusing
                // the state to open the plugin action editor debugger when user setups
                // a non-FC integration
                if (this.props.isFCIntegrationEnabled) {
                  store.dispatch(
                    setPluginActionEditorDebuggerState({
                      open: true,
                      responseTabHeight: 300,
                    }),
                  );
                }
              }}
              open={this.props.isCarbonModalOpen}
              orgName="Appsmith"
              setOpen={(isOpen) => {
                this.props.openCarbonModal?.({ shouldOpen: isOpen as boolean });

                if (onModalStateChange) {
                  onModalStateChange(isOpen as boolean);
                }
              }}
              theme="light"
              tokenFetcher={async () =>
                fetchAccessToken(datasourceId, workspaceId)
              }
            />
          )}
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export const CarbonButton = connect(
  (state: AppState, ownProps: CarbonButtonProps) => {
    const datasource = getDatasource(state, ownProps.datasourceId);
    const plugins = getPlugins(state);

    const integrations = get(
      datasource,
      `datasourceStorages.${state.environments.currentEnvironmentDetails.id}.datasourceConfiguration.properties[1].value`,
    ) as unknown as Integration[];

    const embeddingModel = get(
      datasource,
      `datasourceStorages.${state.environments.currentEnvironmentDetails.id}.datasourceConfiguration.embeddingModel`,
    ) as unknown as EmbeddingGenerators;

    const installingIntegrationName =
      state.entities.aiAgents.installingIntegrationName;
    const isFCIntegrationEnabled = getIsFCIntegrationsEnabled(state);
    const isCarbonModalOpen = state.entities.aiAgents.isCarbonModalOpen;

    return {
      integrations,
      embeddingModel,
      plugins,
      installingIntegrationName,
      isCarbonModalOpen,
      isFCIntegrationEnabled,
    };
  },
  {
    createDatasourceAndSearchQueriesForAiAgent,
    openCarbonModal,
  },
)(_CarbonButton);
