import { getAppsmithConfigs } from "ee/configs";
import { CUSTOMER_PORTAL_PLANS_URL } from "ee/constants/BillingConstants";
import { createMessage, NOT_AVAILABLE } from "ee/constants/messages";
import isNil from "lodash/isNil";
import { dayjs } from "utils/dayJsUtils";

//MMM Do, YYYY translates to Day + ordinal Eg: 1st, 10th etc. Feb 1st, 2023
export const DEFAULT_DATE_FORMAT = "MMM Do, YYYY";
export const DEFAULT_DATE_FORMAT_WITHOUT_YEAR = "MMM Do";

export const goToCustomerPortal = () => {
  window.open(CUSTOMER_PORTAL_PLANS_URL, "_blank");
};

const appsmithConfigs = getAppsmithConfigs();

export const customerPortalPlansUrl = `${appsmithConfigs.customerPortalUrl}/plans`;

export const getDateSuffix = (date = "") => {
  const parsedDate = Number(date);

  if (date !== "" && !isNil(date) && typeof parsedDate === "number") {
    const j = parsedDate % 10,
      k = parsedDate % 100;

    if (j == 1 && k != 11) {
      return "st";
    }

    if (j == 2 && k != 12) {
      return "nd";
    }

    if (j == 3 && k != 13) {
      return "rd";
    }

    return "th";
  } else {
    return "";
  }
};

export const getDateString = (
  timestamp?: number,
  format = DEFAULT_DATE_FORMAT,
) => {
  if (timestamp) {
    return dayjs.unix(timestamp).format(format);
  } else {
    return createMessage(NOT_AVAILABLE);
  }
};

export const getRemainingDaysFromTimestamp = (timestamp = Date.now()) => {
  const totalHours = Math.floor(
    (new Date(timestamp).getTime() - Date.now()) / (1000 * 60 * 60),
  );

  if (totalHours <= 720 && totalHours > 708) {
    return {
      days: 30,
      suffix: "days",
    };
  }

  if (totalHours <= 12) {
    return {
      days: totalHours,
      suffix: totalHours > 1 ? "hours" : "hour",
    };
  }

  const days = Math.floor((totalHours - 12) / 24) + 1;

  return {
    days,
    suffix: days > 1 ? "days" : "day",
  };
};

export const getDateRangeString = (startDate: number, endDate: number) => {
  return `${getDateString(startDate)} - ${getDateString(endDate)}`;
};
